import { useEffect, useState } from 'react';

export const useAfterTimeout = (time: number) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setValue(true);
    }, time);
  }, []);

  return value;
};

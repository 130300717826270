import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next/';

import PreprUtil from '@utils/PreprUtil';
import { GenericService, GraphqlService, MailchimpService, PersonalService } from '@services/index';
import {
  DestinationLinksProvider,
  FooterLinksProvider,
  SiteLinksProvider,
  useLocale,
  useNewsletter,
} from '@hooks/index';
import { ADVERTISEMENT_TYPE, FB_APP_ID, PageProps, REVALIDATION_TIME } from '@constants/index';

import { IHomePublicationFields, HomeModule } from 'modules/HomeModule';

import ListWrapper from '@components/containers/ListWrapper/ListWrapper';
import NewsletterSignupBlock from '@components/containers/NewsletterSignupBlock/NewsletterSignupBlock';
import SearchBlock from '@components/containers/SearchBlock/SearchBlock';

import Layout from '@components/layout/Layout/Layout';
import { Meta } from '@components/layout/Meta/Meta';

import HighlightedNewsList from '@components/lists/HighlightedNewsList/HighlightedNewsList';
import NewsList from '@components/lists/NewsList/NewsList';
import TipList from '@components/lists/TipList/TipList';
import TravelstoryList from '@components/lists/TravelstoryList/TravelstoryList';

import IconHeader from '@components/ui/IconHeader/IconHeader';
import MeetCard from '@components/ui/MeetCard/MeetCard';
import PrimaryHeader from '@components/ui/PrimaryHeader/PrimaryHeader';
import CookieDialog from '@components/forms/CookieDialog/CookieDialog';

import styles from './HomePage.module.scss';
import { getRoute } from '@constants/routes';
import { Advertisement } from '@components/layout/Advertisement/Advertisement';
import { GetStaticPropsContext } from 'next';

const { FacebookProvider } = require('react-facebook');

interface Props extends PageProps<IHomePublicationFields> {}

const Home = ({ fields, destinationLinks, siteLinks, footerLinks }: Props) => {
  const { t } = useTranslation();
  const renderNewsletter = useNewsletter();
  const locale = useLocale();

  const getCorrectCategory = (idx: number) => {
    switch (idx) {
      case 1:
        return fields.second_travelstory_tag;
      case 2:
        return fields.third_travelstory_tag;
      case 3:
        return fields.fourth_travelstory_tag;
      case 4:
        return fields.fifth_travelstory_tag;
      default:
        return fields.first_travelstory_tag;
    }
  };

  const getTravelstories = useCallback(() => {
    return (
      fields.travelstories?.map((item, idx) => {
        return PreprUtil.formatTravelstory(
          item,
          getRoute(locale || 'nl-NL', 'TRAVELSTORY_ARCHIVE'),
          getCorrectCategory(idx),
        );
      }) || []
    );
  }, [fields.travelstories]);

  const getTips = useCallback(() => {
    return (
      fields.tips?.map((item) => {
        return PreprUtil.bucketListToProps(item);
      }) || []
    );
  }, [fields.tips]);

  const renderAboutCorno = useCallback(() => {
    return (
      <MeetCard
        link={{
          label: fields.about_corno_link_label,
          url: fields.about_corno_link_url,
        }}
        title={fields.about_corno_title}
        thumbnail={fields.about_corno_image[0].cdn_files[0]?.full}
        description={fields.about_corno_description}
      />
    );
  }, [
    fields.about_corno_link_label,
    fields.about_corno_link_url,
    fields.about_corno_title,
    fields.about_corno_image,
    fields.about_corno_description,
  ]);

  return (
    <FacebookProvider appId={FB_APP_ID} xfbml>
      <FooterLinksProvider value={footerLinks}>
        <DestinationLinksProvider value={destinationLinks}>
          <SiteLinksProvider value={siteLinks}>
            <Layout paddings={false}>
              <div className={styles.HomePage}>
                <Meta
                  title={fields.meta_title || fields.title}
                  description={fields.meta_description || fields.header_description}
                  image={fields.header_image?.[0]?.cdn_files[0]?.full}
                />
                <div className={styles.Header}>
                  <PrimaryHeader
                    title={fields.header_title}
                    description={fields.header_description}
                    backgroundImage={fields.header_image?.[0].cdn_files[0]?.full}
                    link={{
                      url: fields.header_button_url,
                      label: fields.header_button_label,
                    }}
                  />
                </div>
                <div className="container">
                  <div className={styles.Advertisement}>
                    <Advertisement advertisementType={ADVERTISEMENT_TYPE.ARTICLE} />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={styles.Travelstories}>
                        {!!fields.travelstories && (
                          <TravelstoryList
                            link={{
                              url: siteLinks?.travelstory_archive?.[0]._slug,
                              label: t('DOMAIN.TRAVELSTORY.READ_ALL', {
                                total: fields.total_travelstories,
                              }),
                            }}
                            renderLastItem={renderAboutCorno}
                            stories={getTravelstories()}
                          />
                        )}
                      </div>
                      <div className={styles.SearchBlock}>
                        {!!fields.search_title && (
                          <IconHeader
                            title={fields.search_title}
                            size="lg"
                            icon={require('@assets/images/icons/globe-europe.svg')}
                          />
                        )}
                        <SearchBlock
                          title={fields.search_banner_title}
                          backgroundImage={fields.search_background_image?.[0].cdn_files[0]?.full}
                        />
                      </div>
                      {!!fields.tips && (
                        <div className={styles.Tips}>
                          <ListWrapper
                            title={fields.bucketlist_tips_title}
                            size="lg"
                            icon={require('@assets/images/icons/heart.svg')}
                            link={{
                              url: siteLinks?.destinations_overview[0]._slug,
                              label: t('DOMAIN.BUCKETLIST.ALL_X_TIPS', {
                                total: fields.total_tips,
                              }),
                            }}>
                            <TipList largeTips tips={getTips()} shouldSort={false} />
                          </ListWrapper>
                        </div>
                      )}
                      <div className={styles.Advertisement}>
                        <Advertisement advertisementType={ADVERTISEMENT_TYPE.ARTICLE} />
                      </div>
                      {renderNewsletter && (
                        <div className={styles.Newsletter}>
                          <NewsletterSignupBlock />
                        </div>
                      )}

                      <div className={styles.News}>
                        <HighlightedNewsList
                          title={t('DOMAIN.NEWS.BEST_ARTICLES')}
                          articles={fields.articles}
                        />

                        <NewsList
                          title={t('DOMAIN.NEWS.TRENDING')}
                          icon={require('@assets/images/icons/lightning.svg')}
                          articles={fields.trending_articles}
                          size="sm"
                          className="mt-xl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
            <CookieDialog />
          </SiteLinksProvider>
        </DestinationLinksProvider>
      </FooterLinksProvider>
    </FacebookProvider>
  );
};

export default Home;

export const getStaticProps = async ({ locale, preview }: GetStaticPropsContext) => {
  GraphqlService.setLocale(locale);
  PersonalService.setLocale(locale);
  GenericService.setLocale(locale);
  MailchimpService.setLocale(locale);

  GraphqlService.setPreview(!!preview);

  const homePage = await HomeModule.getSinglePage();

  return {
    props: homePage,
    revalidate: REVALIDATION_TIME,
  };
};

import React, { useCallback } from 'react';
import cn from 'classnames';

import Tip from '@components/ui/Tip/Tip';
import classes from './TipList.module.scss';
import Props from './TipList.props';

const TipList = ({
  tips,
  largeTips = false,
  shouldSort = true,
  responsive = true,
  className,
}: Props) => {
  const getItems = useCallback(() => {
    return shouldSort
      ? tips.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;

          return 0;
        })
      : tips;
  }, [tips]);

  return (
    <div className={cn(responsive && 'row', className)}>
      {getItems().map((tip, i) => {
        if (!tip.thumbnail) return null;

        return (
          <div key={tip.id} className={cn(responsive && 'col-6 col-md-4', tip.className)}>
            <Tip
              isLarge={largeTips}
              className={classes.Tip}
              tip={tip}
              colorType={i % 2 === 0 ? 'secondary' : 'primary'}
              cutTitle={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TipList);

import React from 'react';
import cn from 'classnames';
import Image from 'next/image';

import Card from '../Card/Card';
import TextLink from '../TextLink/TextLink';

import styles from './MeetCard.module.scss';
import Props from './MeetCard.props';

const MeetCard = ({ title, description, thumbnail, link, className }: Props) => {
  return (
    <Card className={cn(styles.MeetCard, className)} hasBackground backgroundType="red">
      <div className={styles.Thumbnail}>
        <div className={styles.ImageWrapper}>
          <Image src={thumbnail} alt="" quality={60} layout="fill" />
        </div>
      </div>
      <div className={styles.Content}>
        <h3 className={cn('h1', styles.Title)}>{title}</h3>
        <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className={styles.Footer}>
        <TextLink href={link.url}>{link.label}</TextLink>
      </div>
    </Card>
  );
};

export default MeetCard;

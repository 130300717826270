import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './FacebookLikeButton.module.scss';
import Props from './FacebookLikeButton.props';

const { Like } = require('react-facebook');

const FacebookLikeButton = ({ url, className }: Props) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return isVisible ? (
    <div className={cn(styles.FacebookLikeButton, className)}>
      <Like href={url} layout="button_count" />
    </div>
  ) : null;
};

export default FacebookLikeButton;

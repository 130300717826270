import React from 'react';
import Image from 'next/image';

import styles from './PrimaryHeader.module.scss';
import Props from './PrimaryHeader.props';
import Button from '../Button/Button';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

const PrimaryHeader = ({ title, description, backgroundImage, link }: Props) => {
  const maxTitleChars = 50;
  const maxDescChars = 100;

  title = title ? title.substr(0, maxTitleChars) + (title.length > maxTitleChars ? '...' : '') : '';

  description = description
    ? description.substr(0, maxDescChars) + (description.length > maxDescChars ? '...' : '')
    : '';

  return (
    <div className={styles.PrimaryHeader}>
      {!!backgroundImage && (
        <Image
          src={backgroundImage}
          alt=""
          quality={60}
          layout="fill"
          className={styles.BackgroundImage}
          data-testid="backgroundImage"
          priority
        />
      )}
      <div className={styles.Content}>
        <div className="container">
          <h1 className={styles.Title} data-testid="title">
            {title}
          </h1>
          <p className={styles.Description} data-testid="description">
            {description}
          </p>
          {!!link && (
            <Button href={link.url} icon={faLongArrowRight} className={styles.Button}>
              {link.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimaryHeader;

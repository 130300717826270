import React from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';

import { Link } from '@components/containers';
import Button from '../Button/Button';

import classes from './Tip.module.scss';
import Props from './Tip.props';

const Tip = ({ tip, isLarge, colorType = 'primary', cutTitle = true, className }: Props) => {
  const { t } = useTranslation();

  const maxTitleChars = 30;
  const shortTitle = cutTitle
    ? tip.title.substring(0, maxTitleChars) + (tip.title.length > maxTitleChars ? '...' : '')
    : tip.title;
  const titleClasses = cn('h1', classes.Title, {
    [classes.LargeTitle]: isLarge,
  });

  return (
    <Link href={tip.url}>
      <div className={classes.AnchorText}>{shortTitle}</div>
      <article
        className={cn(classes.Tip, { [classes.Secondary]: colorType === 'secondary' }, className)}>
        <div className={cn(classes.Wrapper, classes.DefaultView)}>
          <Image
            src={tip.thumbnail}
            alt=""
            quality={60}
            className={classes.Thumbnail}
            layout="fill"
          />
          <div className={classes.ThumbnailOverlay} />
          <h3 className={titleClasses}>{shortTitle}</h3>
        </div>
        <div
          className={cn(classes.Wrapper, classes.InfoView, {
            [classes.SecondaryBackground]: colorType === 'secondary',
          })}>
          <div className={classes.InfoTextWrapper}>
            <span className={titleClasses}>{shortTitle}</span>
            <div className={classes.Excerpt} dangerouslySetInnerHTML={{ __html: tip.excerpt }} />
            <div
              className={cn(classes.Gradient, {
                [classes.GradientPrimary]: colorType === 'primary',
                [classes.GradientSecondary]: colorType === 'secondary',
              })}
            />
          </div>
          <Button
            size="sm"
            theme={`${colorType}-inverted` as any}
            className={classes.Button}
            href={tip.url}>
            {t('GENERIC.READ_MORE')}
          </Button>
        </div>
      </article>
    </Link>
  );
};

export default Tip;

import ListWrapper from '@components/containers/ListWrapper/ListWrapper';
import Article from '@components/ui/Article/Article';
import { IMAGE_SIZES } from '@constants/enums';
import React from 'react';
import { useTranslation } from 'react-i18next/';

import styles from './TravelstoryList.module.scss';
import Props from './TravelstoryList.props';

const NoStoriesmessage = ({ message }: { message: string }) => (
  <p className={styles.NoStories} data-testid="NoStoriesMessage">
    {message}
  </p>
);

const Stories = ({ stories }: Partial<Props>) => {
  if (stories) {
    return (
      <>
        <Article
          {...stories[0]}
          showLikeButton
          thumbnailSize={IMAGE_SIZES.FULL}
          size="xl"
          className={styles.HighlightedItem}
        />
        {stories.slice(1).map((story) => (
          <Article
            {...story}
            className={styles.StoryListItem}
            key={story.url}
            size="sm"
            showLikeButton
          />
        ))}
      </>
    );
  }

  return null;
};

const TravelstoryList = ({ stories, link, titleType, hideTitle, renderLastItem }: Props) => {
  const { t } = useTranslation();
  return (
    <ListWrapper
      icon={require('@assets/images/icons/map.svg')}
      titleType={titleType}
      title={t('DOMAIN.TRAVELSTORY.CORNO_TRAVELSTORIES')}
      link={link}
      hideTitle={hideTitle}
      size="lg">
      <div className={styles.TravelstoryList}>
        {stories.length > 0 ? (
          <>
            <Stories stories={stories} />
            {renderLastItem && renderLastItem()}
          </>
        ) : (
          <NoStoriesmessage message={t('COMPONENTS.TRAVELSTORY_LIST.NO_ARTICLES')} />
        )}
      </div>
    </ListWrapper>
  );
};

export default TravelstoryList;

import React from 'react';
import cn from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'react-i18next/';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import FacebookLikeButton from '../FacebookLikeButton/FacebookLikeButton';

import { useAfterTimeout } from '@hooks/useAfterTimeout';
import { useLocale } from '@hooks/useLocale';
import { IMAGE_SIZES } from '@constants/enums';
import { EN_DOMAIN, NL_DOMAIN } from '@constants/config';
import ArticleProperty from '../ArticleProperty/ArticleProperty';
import Card from '../Card/Card';
import Tag from '../Tag/Tag';

import styles from './Article.module.scss';
import Props from './Article.props';

const Article = ({
  thumbnail,
  tag,
  category,
  title,
  header_subtitle,
  viewcount,
  url,
  size = 'sm',
  showLikeButton = false,
  className,
  thumbnailSize = IMAGE_SIZES.MEDIUM,
}: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const likeButtonTimeoutDone = useAfterTimeout(4000);

  const isDP = locale === 'nl-NL';
  const facebookUrl = `${isDP ? NL_DOMAIN : EN_DOMAIN}${url}`;

  return (
    <Card href={url} className={className}>
      <article className={cn(styles.Article, styles[size])}>
        <div className={styles.Thumbnail}>
          <div className={styles.ImageWrapper}>
            {!!thumbnail && !!thumbnail[thumbnailSize] && (
              <Image src={thumbnail[thumbnailSize]} alt="" quality={60} layout="fill" />
            )}
          </div>
          {!!tag && (
            <Tag className={styles.Tag} size={size}>
              {tag}
            </Tag>
          )}
        </div>
        <div className={styles.Content}>
          <div className={styles.Head}>
            <p className={styles.Category}>{category}</p>
            <ArticleProperty
              icon={faEye}
              label={t('DOMAIN.NEWS.VIEWCOUNT', {
                viewcount: parseInt(viewcount).toLocaleString(locale),
              })}
              size={size}
            />
          </div>
          <h3 className={cn('h1', styles.Title)}>{title}</h3>
          <p className={styles.Excerpt} dangerouslySetInnerHTML={{ __html: header_subtitle }} />

          {showLikeButton && likeButtonTimeoutDone && (
            <div className={styles.Footer}>
              <FacebookLikeButton url={facebookUrl} className={styles.LikeButton} />
            </div>
          )}
        </div>
      </article>
    </Card>
  );
};

export default Article;

import React from 'react';
import cn from 'classnames';

import classes from './Tag.module.scss';
import Props from './Tag.props';

const Tag = ({ children, className, size = 'sm' }: Props) => (
  <span className={cn(classes.Tag, classes[size], className)}>{children}</span>
);

export default Tag;

import React from 'react';
import cn from 'classnames';
import Image from 'next/image';

import SearchForm from '@components/forms/SearchForm/SearchForm';

import styles from './SearchBlock.module.scss';
import Props from './SearchBlock.props';

const SearchBlock = ({ title, backgroundImage, className }: Props) => {
  return (
    <div className={cn(styles.SearchBlock, className)}>
      {!!backgroundImage && (
        <Image
          src={backgroundImage}
          layout="fill"
          alt=""
          quality={60}
          className={styles.Background}
        />
      )}
      <div className={styles.Content}>
        <p className={styles.Title}>{title}</p>
        <SearchForm className={styles.Input} theme="transparent" />
      </div>
    </div>
  );
};

export default SearchBlock;
